/* Customize your main colors in :root variables */
:root {
  --main-background-color: #12173d;
  --card-background-color: #12173d;
  --card-background-lighter-color: #12173d;
  --main-text-color:#F7F6F4;
  --title-text-color:#78fae6;
  --title-text-color2:#e54286;
}
@font-face { font-family: 'Pixelar'; src: url('pixelar.ttf'); }

body{
  background-color:var(--main-background-color);
  font-family: 'Pixelar';
  font-size: 2em;

}
body{
  background-color:var(--card-background-color);
  font-family: 'Pixelar';
  font-size: 1.5em;
}
body{
  background-color:var(--card-background-lighter-color);
  font-family: 'Pixelar';
  font-size: 1.2em;

}
h2, p{
  color: var(--main-text-color);
}

h3{
font-weight: normal;
}

.MuiFab-extended{
  height: auto;
  padding-right: 0 !important;
}

.MuiFab-label{
  font-weight: bold;
  font-family: 'Pixelar';
}

.MuiPaper-root{
  background-color: var(--card-background-color) !important;
}

.MuiButton-containedPrimary:not(.MuiButton-fullWidth){
  border-radius: 24px !important;
  padding: 6px 16px;
}

.MuiButton-label{
  font-size: 1.6em;
  font-family: 'Pixelar';
  font-weight: bold;
}

.MuiLinearProgress-colorPrimary{
  background-color:var(--main-text-color) !important;
}

.MuiLinearProgress-barColorPrimary{
  background-color:var(--title-text-color) !important;
}
